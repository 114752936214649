import logo from './logo.svg';
import './App.css';
import Overlay from './Components/Overlay';
import ImageOverFade from './Components/ImageOverFade'
import OverlaySliderRight from './Components/OverlaySliderRight';
import OverSliderLeft from './Components/OverlaySliderLeft';
import SidebarSlider from './Components/SidebarSlider';
import OverlayZoom from './Components/OverlayZoom';
import OverlayTitle from './Components/OverlayTitle';
import OverlayIcon from './Components/OverlayIcon';
import TranslateTransform from './Components/TTN/TranslateTransform';
import Animation from './Components/TTN/Animation';
import MenuIcon from './Components/TTN/MenuIcon'
import AccordionContainer from './Components/TTN/AccordionContainer';
import SearchMenu from './Components/Navbar/SearchMenu';
import Snap from './Components/Navbar/Snap';
import Carousel from './Components/Navbar/Carousel';
import FixedSidenav from './Components/Navbar/FixedSidenav';
import SidebarOverlay from './Components/Navbar/Sidebar/SidebarOverlay';




function App() {
  return (
    <div className="App">
      {/* <Overlay/> */}
      {/* <ImageOverFade/> */}
      {/* <OverlaySliderRight/> */}
      {/* <OverSliderLeft/> */}
      {/* <SidebarSlider/> */}
{/* <OverlayZoom/> */}
{/* <OverlayTitle/> */}
{/* <OverlayIcon/> */}
{/* <TranslateTransform/> */}
{/* <Animation/> */}
{/* <MenuIcon/> */}
{/* <AccordionContainer/> */}
{/* <SearchMenu/> */}
{/* <Snpa/> */}
{/* <Carousel/> */}
{/* <FixedSidenav/> */}
<SidebarOverlay/>
    </div>
  );
}

export default App;

